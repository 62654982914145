import React, { useContext, useEffect, useState } from 'react';
//Router dom
import { Link } from 'react-router-dom';
//Context
import { RouterContext } from '../../context/RouterContext';

import { checkIfAdmin } from '../../helpers/admin/adminHelper';

//CSS
import './IndexHeader.css'

//MUI
import {
    Button, IconButton, Box, Toolbar, AppBar, Menu, MenuItem, Avatar, Divider, ListItemIcon
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Logout from '@mui/icons-material/Logout';

import logo from '../../Assets/Images/logo_NIN.png';
import banner_desktop from '../../Assets/Images/1440x782/banner_ind_1.jpg';
import banner_desktop_1 from '../../Assets/Images/1440x782/banner_ind_2.jpg';
import banner_desktop_2 from '../../Assets/Images/1440x782/banner_ind_3.jpg';
import banner_tablet from '../../Assets/Images/1600x900/HomePage.jpg';
import banner_tablet_1 from '../../Assets/Images/1600x900/Homepage2.jpg';
import banner_tablet_2 from '../../Assets/Images/1600x900/Homepage3.jpg';
import banner_mobile from '../../Assets/Images/1080x920/HomePage1.jpg';
import banner_mobile_1 from '../../Assets/Images/1080x920/HomePage2.jpg';
import banner_mobile_2 from '../../Assets/Images/1080x920/HomePage3.jpg';

//Components
import Sidebar from '../Sidebar/Sidebar';

//carousel
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const IndexHeader = () => {
    const { item: { email, username, admin_id }, userLogout } = useContext(RouterContext);

    const [navbar, setNavbar] = useState(false);

    const changeBackground = () => {
        if (window.scrollY >= 150) {
            setNavbar(true)
        } else {
            setNavbar(false)
        }
    };

    useEffect(() => {
        changeBackground();
        window.addEventListener("scroll", changeBackground);
    });

    const [drawer, setDrawer] = useState(false)
    const openDrawer = () => {
        setDrawer(true)
    };

    //Menu usuario logueado
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const openMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const closeMenu = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <AppBar className={
                navbar ? "navbar active" : "navbar"
            } elevation={navbar ? 4 : 0}>
                <Toolbar>
                    <Box sx={{ mt: 1 }}>
                        <Link to='/'>
                            <img src={logo} alt={logo} width='90' />
                        </Link>
                    </Box>
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <Box sx={{ gap: '20px', display: { xs: 'none', sm: 'none', md: 'flex', textShadow: navbar ? 'none' : '2px 2px #000' } }} >
                        <Button component={Link} to="/" sx={{ color: navbar ? '#000' : '#fff', textShadow: navbar ? 'none' : '2px 2px #000' }}>Inicio</Button>
                        <Button component={Link} to="/nosotros" sx={{ color: navbar ? '#000' : '#fff', textShadow: navbar ? 'none' : '2px 2px #000' }}>Nosotros</Button>
                        <Button component={Link} to="/desarrollo" sx={{ color: navbar ? '#000' : '#fff', textShadow: navbar ? 'none' : '2px 2px #000' }}>Desarrollo</Button>
                        <Button component='a' href="https://www.subscribepage.com/kidneyday2025" sx={{ color: '#fff' }}>Eventos</Button>
                        <Box sx={{ gap: '20px', display: { xs: 'none', sm: 'none', md: 'none', lg: 'none', xl: 'flex' } }}>
                            <Button component={Link} to="/investigacion" sx={{ color: navbar ? '#000' : '#fff', textShadow: navbar ? 'none' : '2px 2px #000' }}>Investigación clínica</Button>
                            <Button component={Link} to="/linea-de-productos" sx={{ color: navbar ? '#000' : '#fff', textShadow: navbar ? 'none' : '2px 2px #000' }}>Productos</Button>
                            <Button component={Link} to="/nin-academy" sx={{ color: navbar ? '#000' : '#fff', textShadow: navbar ? 'none' : '2px 2px #000' }}>NIN Academy</Button>
                            <Button component={Link} to="/blog" sx={{ color: navbar ? '#000' : '#fff', textShadow: navbar ? 'none' : '2px 2px #000' }}>Blog</Button>
                        </Box>
                        <Box sx={{ my: 'auto' }}>
                            {
                                email !== '' ?
                                    <Avatar onClick={openMenu}
                                        sx={{ bgcolor: navbar ? '#000' : '#fff', color: navbar ? '#fff' : '#000', cursor: 'pointer', width: 30, height: 30, textShadow: 'none' }}
                                    >
                                        {username.charAt(0)}
                                    </Avatar>
                                    :
                                    <Avatar component={Link} to='/iniciar-sesion'
                                        sx={[
                                            {
                                                bgcolor: navbar ? '#000' : '#fff', color: navbar ? '#fff' : '#000', width: 30, height: 30
                                            },
                                            {
                                                '&:hover': {
                                                    color: navbar ? '#fff' : '#000',
                                                },
                                            },
                                        ]}>
                                    </Avatar>
                            }
                            {
                                email !== '' &&
                                <Menu
                                    anchorEl={anchorEl}
                                    id="account-menu"
                                    open={open}
                                    onClose={closeMenu}
                                    onClick={closeMenu}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            '&:before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 9,
                                                width: 10,
                                                height: 10,
                                                bgcolor: 'background.paper',
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    {
                                        email !== '' &&
                                        <MenuItem
                                            component={Link}
                                            to={
                                                checkIfAdmin(admin_id) ?
                                                    '/nin-admin'
                                                    :
                                                    '/mi-cuenta'
                                            }
                                            sx={[{ '&:hover': { color: '#000' } }]}
                                        >
                                            <Avatar />
                                            {
                                                checkIfAdmin(admin_id) ?
                                                    'NIN Admin'
                                                    :
                                                    "Cuenta"
                                            }
                                        </MenuItem>
                                    }
                                    {
                                        (email !== '' && checkIfAdmin(admin_id)) &&
                                        <Divider />
                                    }
                                    <MenuItem onClick={userLogout}>
                                        <ListItemIcon>
                                            <Logout fontSize="small" />
                                        </ListItemIcon>
                                        Cerrar sesión
                                    </MenuItem>
                                </Menu>
                            }
                        </Box>
                    </Box>
                    <Box
                        sx={{ display: { sm: 'block', md: 'block', lg: 'block', xl: 'none' } }}
                    >
                        <IconButton
                            size="large"
                            edge="end"
                            sx={{ ml: 2, color: navbar ? '#000' : '#fff' }}
                            onClick={openDrawer}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>

            {/* Banner's section */}
            <div className='banners-desktop'>
                <Carousel showStatus={false} showThumbs={false} showArrows={false} swipeable={true} emulateTouch={true} infiniteLoop>
                    <div>
                        <img src={banner_desktop} alt={banner_desktop} />
                    </div>
                    <div>
                        <img src={banner_desktop_1} alt={banner_desktop_1} />
                    </div>
                    <div>
                        <img src={banner_desktop_2} alt={banner_desktop_2} />
                    </div>
                </Carousel>
            </div>

            <div className='banners-tablet'>
                <Carousel showStatus={false} showThumbs={false} showArrows={false} swipeable={true} emulateTouch={true} infiniteLoop>
                    <div>
                        <img src={banner_tablet} alt={banner_tablet} />
                    </div>
                    <div>
                        <img src={banner_tablet_1} alt={banner_tablet_1} />
                    </div>
                    <div>
                        <img src={banner_tablet_2} alt={banner_tablet_2} />
                    </div>
                </Carousel>
            </div>

            <div className='banners-mobile'>
                <Carousel showStatus={false} showThumbs={false} showArrows={false} swipeable={true} emulateTouch={true} infiniteLoop>
                    <div>
                        <img src={banner_mobile} alt={banner_mobile} />
                    </div>
                    <div>
                        <img src={banner_mobile_1} alt={banner_mobile_1} />
                    </div>
                    <div>
                        <img src={banner_mobile_2} alt={banner_mobile_2} />
                    </div>
                </Carousel>
            </div>

            <Sidebar drawer={drawer} setDrawer={setDrawer} />
        </>
    )
}

export default IndexHeader