import React, { useContext, useState } from 'react';

//Context
import { RouterContext } from '../../context/RouterContext';

import { checkIfAdmin } from '../../helpers/admin/adminHelper';

//MUI
import {
  AppBar, Avatar, Box, Button, Divider, IconButton, ListItemIcon, Menu, MenuItem, Toolbar
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Logout from '@mui/icons-material/Logout';

import logo from '../../Assets/Images/logo_NIN.png';

//Router dom
import { Link } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';

const Header = () => {
  const { item: { email, username, admin_id }, userLogout } = useContext(RouterContext);

  const [drawer, setDrawer] = useState(false)
  const openDrawer = () => {
    setDrawer(true);
  };

  //Menu usuario logueado
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar position="sticky" sx={{ backgroundColor: '#fff' }} elevation={4}>
        <Toolbar>
          <Box sx={{ mt: 1 }}>
            <Link to='/'>
              <img src={logo} alt={logo} width='90' />
            </Link>
          </Box>
          <Box sx={{ flexGrow: 1 }}></Box>
          <Box sx={{ gap: '20px', display: { xs: 'none', sm: 'none', md: 'flex' } }} >
            <Button component={Link} to="/" sx={{ color: '#000' }}>Inicio</Button>
            <Button component={Link} to="/nosotros" sx={{ color: '#000' }}>Nosotros</Button>
            <Button component={Link} to="/desarrollo" sx={{ color: '#000' }}>Desarrollo</Button>
            <Button component='a' href="https://www.subscribepage.com/kidneyday2025" sx={{ color: '#000' }}>Eventos</Button>
            <Box sx={{ gap: '20px', display: { xs: 'none', sm: 'none', md: 'none', lg: 'none', xl: 'flex' } }}>
              <Button component={Link} to="/investigacion" sx={{ color: '#000' }}>Investigación clínica</Button>
              <Button component={Link} to="/linea-de-productos" sx={{ color: '#000' }}>Productos</Button>
              <Button component={Link} to="/nin-academy" sx={{ color: '#000' }}>NIN Academy</Button>
              <Button component={Link} to="/blog" sx={{ color: '#000' }}>Blog</Button>
            </Box>
            <Box sx={{ my: 'auto' }}>
              {
                email !== '' ?
                  <Avatar onClick={openMenu}
                    sx={{ bgcolor: '#000', color: '#fff', cursor: 'pointer', width: 30, height: 30 }}
                  >
                    {username.charAt(0)}
                  </Avatar>
                  :
                  <Avatar component={Link} to='/iniciar-sesion'
                    sx={[
                      {
                        bgcolor: '#000', color: '#fff', width: 30, height: 30
                      },
                      {
                        '&:hover': {
                          color: '#fff',
                        },
                      },
                    ]}>
                  </Avatar>
              }
              {
                email !== '' &&
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={closeMenu}
                  onClick={closeMenu}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 9,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  {
                    email !== '' &&
                    <MenuItem
                      component={Link}
                      to={
                        checkIfAdmin(admin_id) ?
                          '/nin-admin'
                          :
                          '/mi-cuenta'
                      }
                      sx={[{ '&:hover': { color: '#000' } }]}
                    >
                      <Avatar />
                      {
                        checkIfAdmin(admin_id) ? 'NIN Admin' : "Cuenta"
                      }
                    </MenuItem>
                  }
                  {
                    (email !== '' && checkIfAdmin(admin_id))
                    && <Divider />
                  }
                  <MenuItem onClick={userLogout}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Cerrar sesión
                  </MenuItem>
                </Menu>
              }
            </Box>
          </Box>
          <Box
            sx={{ display: { sm: 'block', md: 'block', lg: 'block', xl: 'none' } }}
          >
            <IconButton
              size="large"
              edge="end"
              sx={{ ml: 2, color: '#000' }}
              onClick={openDrawer}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      <Sidebar drawer={drawer} setDrawer={setDrawer} />
    </>
  )
}

export default Header