import React, { useContext, useState } from 'react';

//Router dom
import { Link } from 'react-router-dom';

//Context
import { RouterContext } from '../../context/RouterContext';

import { checkIfAdmin } from '../../helpers/admin/adminHelper';

import logo from '../../Assets/Images/logo_NIN.png'

//MUI
import {
    Box, Container, Drawer, IconButton, Button, Stack,
    Avatar, Menu, MenuItem, Divider, ListItemIcon
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Logout from '@mui/icons-material/Logout';

const Sidebar = ({ drawer, setDrawer }) => {

    const { item: { email, username, admin_id }, userLogout } = useContext(RouterContext);

    const closeDrawer = () => {
        setDrawer(false)
    }

    //Menu usuario logueado
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const openMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const closeMenu = () => {
        setAnchorEl(null);
    };

    return (
        <Drawer
            open={drawer}
            onClose={closeDrawer}
            anchor='top'
            PaperProps={{
                sx: { height: '100%' },
            }}
            transitionDuration={{ enter: 800, exit: 200 }}
        >
            <>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 2.5 }}>
                    <img src={logo} alt={logo} width='80' />
                    <IconButton onClick={closeDrawer}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Container maxWidth='xl' sx={{ my: 3 }}>
                    <Stack spacing={2}>
                        <Button color='inherit' size='large' sx={{ fontSize: '20px', fontWeight: 'bold' }} component={Link} to="/" onClick={closeDrawer}>Inicio</Button>
                        <Button color='inherit' size='large' sx={{ fontSize: '20px', fontWeight: 'bold' }} component={Link} to="/nosotros" onClick={closeDrawer}>Nosotros</Button>
                        <Button color='inherit' size='large' sx={{ fontSize: '20px', fontWeight: 'bold' }} component={Link} to="/desarrollo" onClick={closeDrawer}>Desarrollo</Button>
                        <Button color='inherit' size='large' sx={{ fontSize: '20px', fontWeight: 'bold' }} component='a' href="https://www.subscribepage.com/kidneyday2025" onClick={closeDrawer}>Eventos</Button>
                        <Button color='inherit' size='large' sx={{ fontSize: '20px', fontWeight: 'bold' }} component={Link} to="/investigacion" onClick={closeDrawer}>Investigación clínica</Button>
                        <Button color='inherit' size='large' sx={{ fontSize: '20px', fontWeight: 'bold' }} component={Link} to="/linea-de-productos" onClick={closeDrawer}>Productos</Button>
                        <Button color='inherit' size='large' sx={{ fontSize: '20px', fontWeight: 'bold' }} component={Link} to="/nin-academy" onClick={closeDrawer}>Nin Academy</Button>
                        <Button color='inherit' size='large' sx={{ fontSize: '20px', fontWeight: 'bold' }} component={Link} to="/blog" onClick={closeDrawer}>Blog</Button>
                        <Box>
                            {
                                email !== '' ?
                                    <Avatar onClick={openMenu}
                                        sx={{ bgcolor: '#000', color: '#fff', cursor: 'pointer', mx: 'auto' }}
                                    >
                                        { username.charAt(0) }
                                    </Avatar>
                                    :
                                    <Avatar component={Link} to='/iniciar-sesion'
                                        sx={[
                                            {
                                                bgcolor: '#000', color: '#fff', mx: 'auto'
                                            },
                                            {
                                                '&:hover': {
                                                    color: '#000',
                                                },
                                            },
                                        ]}>
                                    </Avatar>
                            }
                            {
                                email !== '' &&
                                <Menu
                                    anchorEl={anchorEl}
                                    id="account-menu"
                                    open={open}
                                    onClose={closeMenu}
                                    onClick={closeMenu}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            '&:before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 9,
                                                width: 10,
                                                height: 10,
                                                bgcolor: 'background.paper',
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    {
                                        email !== '' &&
                                            <>
                                                <MenuItem
                                                    component={Link}
                                                    to={
                                                        checkIfAdmin(admin_id) ?
                                                            '/nin-admin'
                                                        :
                                                            '/mi-cuenta'
                                                    }
                                                    sx={[{ '&:hover': { color: '#000' } }]}
                                                >
                                                    <Avatar />
                                                    {
                                                        checkIfAdmin(admin_id) ?
                                                            'NIN Admin'
                                                        :
                                                            "Cuenta"
                                                    }
                                                </MenuItem>
                                                <Divider />
                                            </>
                                    }
                                    <MenuItem onClick={ userLogout }>
                                        <ListItemIcon>
                                            <Logout fontSize="small" />
                                        </ListItemIcon>
                                        Cerrar sesión
                                    </MenuItem>
                                </Menu>
                            }
                        </Box>
                    </Stack>
                </Container>
            </>
        </Drawer>
    );
};

export default Sidebar;